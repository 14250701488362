import { Helmet } from "react-helmet-async";
import CardLayout from "../../layouts/CardLayout";
import React, { useEffect, useState } from "react";
import errorHandler from "../../utils/errorHandler";
import { useParams } from "react-router-dom";
import { Grid, Card, CardHeader, CardContent, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from "@mui/material";
import { saveAs } from "file-saver";
import TeacherManagementService from "../../services/TeacherManagementService";
import {capitalize, capitalizeText} from "../../utils/helpers";

const ViewReportPerTaskForStudent = () => {
    const { studentId, courseId, taskId } = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const payload = {
                studentId: studentId,
                courseId: courseId,
                taskId: taskId,
            };
            try {
                const response = await TeacherManagementService.liviewReportPerTask(payload);
                setData(response?.data?.data);
            } catch (error) {
                errorHandler(error);
            }
        };
        fetchData();
    }, [studentId, courseId, taskId]);

    const downloadCSV = () => {
        if (data.length === 0) {
            alert("No data to download.");
            return;
        }
    
        const headers = ["No.", "Audio", "Option 1", "Option 2", "Option 3", "Correct Answer", "Your Answer"];
        const csvRows = [headers.join(",")];
    
        data.forEach((item, itemIndex) => {
            item?.questionData?.questions.forEach((question, questionIndex) => {
                const row = [
                    itemIndex + 1, // Question number
                    question.audio, // Audio file name
                ];
    
                // Add the options dynamically
                const options = item.options || [];
                // Option 1 and Option 2 are always there if available, Option 3 is conditional
                row.push(options[0] ? capitalize(options[0]) : ''); // Option 1
                row.push(options[1] ? capitalize(options[1]) : ''); // Option 2
                row.push(options[2] ? capitalize(options[2]) : ''); // Option 3 (empty if no third option)
    
                // Correct Answer (if it's within the options)
                const correctAnswer = question.options[parseInt(question.correctAnswer)];
                row.push(capitalize(correctAnswer));
    
                // Add the student's answer with correctness
                row.push(`${capitalize(item?.answer)}${item.correct ? " (Correct)" : " (Incorrect)"}`);
    
                // Push the row to the CSV content
                csvRows.push(row.join(","));
            });
        });
    
        // Create the CSV blob and save it
        const blob = new Blob([csvRows.join("\n")], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, `Task_Report_${studentId}.csv`);
    };
    
    return (
        <Box>
            <Helmet>
                <title>Student live Report Per Task</title>
            </Helmet>
            <CardLayout>
                <CardHeader
                    titleTypographyProps={{ variant: "h4" }}
                    title="Student live Report Per Task"
                />
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button variant="contained" color="primary" onClick={downloadCSV}>
                        Download CSV
                    </Button>
                </Box>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Card sx={{ boxShadow: 4, borderRadius: 3, overflow: "hidden" }}>
                                <CardContent>
                                    {data.length > 0 ? (
                                        <>
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>No.</TableCell>
                                                            <TableCell>Audio</TableCell>
                                                            <TableCell>Options</TableCell>
                                                            <TableCell>Correct Answer</TableCell>
                                                            <TableCell>Your Answer</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data.map((item, itemIndex) => (
                                                            item?.questionData?.questions.map((question, questionIndex) => (
                                                                <TableRow key={`${itemIndex}-${questionIndex}`}>
                                                                    <TableCell>{itemIndex + 1}</TableCell>
                                                                    <TableCell>{question.audio}</TableCell>
                                                                    <TableCell>{capitalizeText(item.options.join(', '))}</TableCell>
                                                                    <TableCell>{capitalize(question.options[parseInt(question.correctAnswer)])}</TableCell>
                                                                    <TableCell>{capitalize(item.answer)} ({item.correct ? "Correct" : "Incorrect"})</TableCell>
                                                                </TableRow>
                                                            ))
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>
                                    ) : (
                                        <div>No grades available</div>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardLayout>
        </Box>
    );
};

export default ViewReportPerTaskForStudent;




