import React, { useEffect, useState, useCallback, useRef } from "react";
import { Icon } from "@iconify/react";
import { Box, Button, CardContent, CardHeader, Grid, InputLabel, MenuItem, Select, TextField, Typography, FormControl, IconButton, FormHelperText } from "@mui/material";
import { FormikProvider, useFormik, FieldArray, Form, useFormikContext } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import CardLayout from "../../layouts/CardLayout";
import { TaskSchema } from "../../utils/Schema";
import taskAndQuestionnair from "../../services/taskQuestionniarService";
import errorHandler from "../../utils/errorHandler";
import FieldErrorMessage from "../../components/FieldErrorMessage";
import SaveButton from "../../components/SaveButton";
import { toast } from "react-toastify";
import useS3FileUpload from "../../hooks/useS3FileUpload";
import { AUDIO_BASE } from "../../utils/constants";
import { FixedSizeList as List } from "react-window";

const Row = React.memo(({ index, style }) => {
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const question = values.questions[index];

  return (
    <div style={style}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} className={index + 1 !== 1 ? "question-spacing" : ""}>
          <Typography>
            Question {index + 1}
          </Typography>
        </Grid>

        <FieldArray name={`questions[${index}].options`}>
          {({ remove: removeOption, push: pushOption }) => (
            <>
              {question.options.map((option, optionIndex) => (
                <>
                <Grid key={`${optionIndex}` + `${index}`} item xs={5}>
                  <TextField
                    fullWidth
                    disabled
                    label={`Option ${optionIndex + 1}`}
                    value={option}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (newValue !== option) {
                        setFieldValue(`questions[${index}].options[${optionIndex}]`, newValue);
                      }
                    }}
                    error={Boolean(
                      touched.questions?.[index]?.options?.[optionIndex] &&
                      errors.questions?.[index]?.options?.[optionIndex]
                    )}
                  />
                  <FieldErrorMessage
                    name={`questions[${index}].options[${optionIndex}]`}
                  />
                  <FormHelperText className='error-custom'>
                    {touched.questions?.[index]?.options?.[optionIndex] && errors.questions?.[index]?.options?.[optionIndex]}
                  </FormHelperText>
                </Grid>
                </>
              ))}
            </>
          )}
        </FieldArray>
        {/* Type of Trial */}
        <Grid item xs={12}>
          <FormControl
            fullWidth
            error={Boolean(
              touched.questions?.[index]?.trailPart &&
              errors.questions?.[index]?.trailPart
            )}
          >
            <InputLabel>Type of Trial</InputLabel>          
            <Select
              label={"Type of Trial"}
              disabled
              value={question.trailPart || ""}
              onChange={(e) =>
                setFieldValue(
                  `questions[${index}].trailPart`,
                  e.target.value
                )
              }
            >
              <MenuItem value={""} disabled>
                Select a Type of Trial``
              </MenuItem>
              <MenuItem value={"practice"}>Practice</MenuItem>
              <MenuItem value={"test"}>Test</MenuItem>
            </Select>

            <FormHelperText className='error-custom'>
              {touched.questions?.[index]?.trailPart && errors.questions?.[index]?.trailPart}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl
            fullWidth
            disabled
            error={Boolean(
              touched.questions?.[index]?.correctAnswer &&
              errors.questions?.[index]?.correctAnswer
            )}
          >
            <InputLabel>Correct Answer</InputLabel>
            <Select
              label={"Correct Answer"}
              value={question.correctAnswer}
              onChange={(e) =>
                setFieldValue(
                  `questions[${index}].correctAnswer`,
                  e.target.value
                )
              }
            >
              {question.options.map((_, optionIndex) => (
                <MenuItem key={optionIndex} value={optionIndex}>
                  Option {optionIndex + 1}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText className='error-custom'>
              {touched.questions?.[index]?.correctAnswer && errors.questions?.[index]?.correctAnswer}
            </FormHelperText>
          </FormControl>
        </Grid>

        {question?.viewPath ? (
          <>
            <Grid item md={4}>
              <audio src={question?.viewPath} controls />
            </Grid>
            <Grid item md={8}>
              <b>{question?.audio}</b>
            </Grid>
          </>
        ) : null}
      </Grid>
    </div>
  );
});

function ViewDetailsTask() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [dropDownLanguage, setDropDownLanguage] = useState([]);
  const [message, setMessage] = useState("");
  const { fileUrls, uploadProgress, uploadToS3, error } = useS3FileUpload();
  const fileUploadRef = useRef();

  const handleUpload = async (e, index) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length === 0) {
      setMessage("Please select a file.");
      return;
    }
    try {
      await uploadToS3({
        files: selectedFiles,
        maxSizeMB: 5,
        index: index,
        onProgress: (progress) => {},
        onComplete: (uploadedFiles) => {
          setMessage("File uploaded successfully!");
          let ques = uploadedFiles?.map((item) => ({
            audio: item?.fileName,
            viewPath: AUDIO_BASE + item?.fileName,
            correctAnswer: null,
            trailPart:null,
            options: ['', '']
          })
          );
          formik.setFieldValue('questions', [...formik.values.questions, ...ques]);
        },
        onError: (uploadError) => {
          setMessage(`Error: ${uploadError.message}`);
          toast.error(uploadError.message, { toastId: "error2" });
        },
      });
    } catch (uploadError) {
      setMessage(`Error: ${uploadError.message}`);
    }
  };

  const fetchTaskData = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await taskAndQuestionnair.getById({ id });
        const resData = response?.data?.data;
        const urls = await Promise.all(
          response.data?.data?.questions?.map(async (item) => {
            const url = AUDIO_BASE + item.audio;
            return { ...item, viewPath: url };
          }))
        resetForm({
          values: {
            id: resData?._id,
            title: resData?.title || "",
            description: resData?.description || "",
            languageId: resData?.languageId?._id || "",
            status: resData?.status || "false",
            questions: urls || [],
          },
        });
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }
  }, [id]);

  const fetchDropDownLangauge = async () => {
    try {
      let res = await taskAndQuestionnair.getDropdownLanguages({ slug: "Language" });
      setDropDownLanguage(res?.data?.data);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    fetchDropDownLangauge();
  }, []);

  useEffect(() => {
    fetchTaskData();
  }, [fetchTaskData]);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      languageId: "",
      status: true,
      questions: [],
    },
    validationSchema: TaskSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const payload = {
          title: values.title,
          description: values.description,
          languageId: values.languageId,
          status: values.status,
          questions: values.questions,
        };

        if (id) {
          payload.id = values.id;
        }
        const response = id
          ? await taskAndQuestionnair.edit(payload)
          : await taskAndQuestionnair.add(payload);
        navigate(-1);
        toast.success(response?.data?.message);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const { values, errors, touched, handleSubmit, getFieldProps, resetForm, setFieldValue } = formik;

  const [showProgress, setShowProgress] = useState(true);

  useEffect(() => {
    let timer;
    if (uploadProgress === 100) {
      timer = setTimeout(() => {
        setShowProgress(false);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [uploadProgress]);

  return (
    <Box>
      <CardLayout>
        <CardHeader title={`View Task`} />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Title"
                    required
                    error={Boolean(touched?.title && errors?.title)}
                    {...getFieldProps("title")}
                    disabled
                  />
                  <FieldErrorMessage formik={formik} name="title" />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth error={Boolean(touched?.languageId && errors?.languageId)}>
                    <InputLabel id="language-select-label">Language</InputLabel>
                    <Select
                      labelId="language-select-label"
                      id="language-select"
                      value={values.languageId}
                      label="Language"
                      onChange={(e) => setFieldValue("languageId", e.target.value)}
                      disabled
                    >
                      {dropDownLanguage?.map((item) => <MenuItem key={item?._id} value={item?._id}>{item?.title}</MenuItem>)}
                    </Select>
                  </FormControl>
                  <FieldErrorMessage formik={formik} name="languageId" />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={10}
                    fullWidth
                    label="Description"
                    required
                    error={Boolean(touched?.description && errors?.description)}
                    {...getFieldProps("description")}
                    disabled
                  />
                  <FieldErrorMessage formik={formik} name="description" />
                </Grid>

                <CardHeader title={`Questions`} />
                <Grid item xs={12} className="question-box">
                  {errors.questions && typeof errors.questions === 'string' && (
                    <Typography color="error">{errors.questions}</Typography>
                  )}
                  <FieldArray name="questions">
                    {({ remove, push }) => (
                      <>
                        <List
                          height={600}
                          itemCount={values.questions.length}
                          itemSize={480}
                          width="100%"
                        >
                          {Row}
                        </List>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          accept="audio/*"
                          onChange={(e) => handleUpload(e)}
                          className="uploadAudiobox"
                          ref={fileUploadRef}
                          multiple
                        />
                      </>
                    )}
                  </FieldArray>
                  <Grid>
                    {uploadProgress > 0 && showProgress && (
                      <>
                        <InputLabel>Upload Progress: {uploadProgress}%</InputLabel>
                        <progress value={uploadProgress} max="100" className="progressbar">
                          {uploadProgress}%
                        </progress>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth error={Boolean(touched?.status && errors?.status)}>
                    <InputLabel id="status-select-label">Status</InputLabel>
                    <Select
                      labelId="status-select-label"
                      id="status-select"
                      value={values.status}
                      label="Status"
                      onChange={(e) => setFieldValue("status", e.target.value)}
                      disabled
                    >
                      <MenuItem value={true}>Active</MenuItem>
                      <MenuItem value={false}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                  <FieldErrorMessage formik={formik} name="status" />
                </Grid>
                {/* <Grid item xs={12}>
                  <SaveButton loading={loading} id={id} />
                </Grid> */}
              </Grid>
            </Form>
          </FormikProvider>
        </CardContent>
      </CardLayout>
    </Box>
  );
}

export default ViewDetailsTask;