import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, CardContent, Stack, TextField, Typography, CircularProgress } from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { EMAIL_REGEX } from "../../utils/constants";
import apiUsers from "../../services/authServiceLogin";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";

function GlobalSetting() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const settingData = async () => {
    try {
      setLoading(true);
      const response = await apiUsers.getSetting();
      setData(response.data.data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    settingData();
  }, []);

  const initialValues = {
    id: data?._id || "",
    timer: data?.timer ? data?.timer : "",
    // timer: data?.timer ? data?.timer / 60 :"",    
    // instaLink: data?.instaLink ?? "",
    // fbLink: data?.fbLink ?? "",
    // contactUsEmail: data?.contactUsEmail ?? "",
  };

  const validationSchema = Yup.object().shape({
    timer: Yup.number()
      .required("Timer is required")
      .min(15, "The timer must be at least 15 seconds.")
      .max(300, "The timer must be at most 300 seconds."),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      apiUsers.updateSetting(values)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res?.data?.message);
          }
          setSubmitting(false);
          settingData();
        })
        .catch((err) => {
          errorHandler(err);
          setSubmitting(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit } = formik;
  return (
    <Card>
      <CardContent>
        <FormikProvider value={formik} style={{ padding: "34px 30px" }}>
          <Typography variant="h4" sx={{ mb: 2 }} align="start">
            Global Settings
          </Typography>
          {loading ? <CircularProgress /> : (
            <Form>
              <Stack spacing={3}>
                <TextField
                  name="timer"
                  label="Question Time Out Limit*"
                  value={formik.values.timer}
                  type="number"
                  onChange={formik.handleChange}
                  fullWidth
                  error={Boolean(touched.timer && errors.timer)}
                  helperText={touched.timer && errors.timer}
                />
              </Stack>

              <Button size="large" type="submit" variant="contained" disabled={isSubmitting} sx={{ my: 2 }}>
                Update
              </Button>
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  if (!isSubmitting) navigate('/dashboard');
                }}
                sx={{ my: 2, ml: 4 }}
              >
                Cancel
              </Button>
            </Form>
          )}
        </FormikProvider>
      </CardContent>
    </Card>
  );
}

export default GlobalSetting;