import React, { useState, useCallback } from "react";
import axios from "axios";
import { AUDIO_FORMATS } from "../utils/constants";
import taskAndQuestionnair from "../services/taskQuestionniarService";

const validateFile = (file, formats, maxSizeMB) => {
  if (!file) {
    throw new Error("No file selected.");
  }
  if (!formats.includes(file.type)) {
    throw new Error(
      `Invalid file format. Allowed formats: ${formats.join(", ")}`
    );
  }
  if (file.size > maxSizeMB * 1024 * 1024) {
    throw new Error(`File size exceeds ${maxSizeMB} MB.`);
  }

  return true;
};

const uploadFileToS3 = async (url, file, onProgress) => {
  try {
    const response = await axios.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onProgress(percentage);
      },
    });
    return response;
  } catch (error) {
    console.log("errror",error)
    throw new Error(`Failed to upload file: ${error.message}`);
  }
};

const useS3FileUpload = ({ initialProgress = 0 } = {}) => {
  const [fileUrls, setFileUrls] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);

  const uploadToS3 = useCallback(
    async ({
      files,
      maxSizeMB = 5,
      index,
      onProgress = () => {},
      onError = () => {},
      onComplete = () => {},
    }) => {
      setUploadProgress(0);
      setError(null);

      try {
        const uploadPromises = files.map(async (file) => {
          validateFile(file, AUDIO_FORMATS, maxSizeMB);

          const fileKey = `${Date.now()}-${file.name}`;
          const getPresignedUrl = taskAndQuestionnair.getPresignedUrl;

          const presignedUrlResponse = await getPresignedUrl({
            fileName: fileKey,
          });
          const uploadUrl = presignedUrlResponse.data.url;
          const fileUrl = presignedUrlResponse.data.fileUrl;
          const fileName = presignedUrlResponse.data.fileName;

          await uploadFileToS3(uploadUrl, file, (progress) => {
            setUploadProgress(progress);
            onProgress(progress);
          });

          return { fileUrl, fileName };
        });

        const uploadedFiles = await Promise.all(uploadPromises);
        setFileUrls(uploadedFiles);
        onComplete(uploadedFiles);
      } catch (error) {
        setError(error.message);
        onError(error);
      }
    },
    []
  );

  return { fileUrls, uploadProgress, uploadToS3, error };
};

export default useS3FileUpload;