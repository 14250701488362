import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import EditAddCoursesManagement from "../pages/coursesmanagement/Edit";
import CoursesManagement from "../pages/coursesmanagement/List";
import ViewCourceManagement from "../pages/coursesmanagement/View";
import EditAddEmail from "../pages/emailTemplate/Edit";
import EmailTemplateLits from "../pages/emailTemplate/List";
import ViewEmailTemplate from "../pages/emailTemplate/View";
import EditAddIrbc from "../pages/irbconsentform/Edit";
import AddStudentManagement from "../pages/student-management/Add";
import EditStudentManagement from "../pages/student-management/Edit";
import StudentManagement from "../pages/student-management/List";
import ViewStudentManagement from "../pages/student-management/View";
import ViewStudentGrades from "../pages/student-management/ViewGrade";
import AddTeacherManagement from "../pages/teacherManagement/Add";
import AllStudentList from "../pages/teacherManagement/AllStudentList";
import EditTeacherManagement from "../pages/teacherManagement/Edit";
import TeacherManagement from "../pages/teacherManagement/List";
import ViewTeacherManagement from "../pages/teacherManagement/View";
import GlobalSetting from "../pages/Global Setting";
import StudentliviewReportPerTask from "../pages/student-management/StudentliviewReportPerTask";
import ViewReportPerTaskForStudent from "../pages/taskManagement/viewReportPerTaskForStudent";
import ViewDetailsTask from "../pages/taskManagement/view";
// import ListNotification from "../ pages/Notificationmanagement/ListNotification"
const ChangePassword = lazy(() => import("../pages/auth/ChangePassword"));

//Language........................................................................................
const ListLanguage = lazy(() => import("../pages/metadataManagement/language/list"));
const AddLanguage = lazy(() => import("../pages/metadataManagement/language/Edit"));

//Institution........................................................................................
const ListInstitution = lazy(() => import("../pages/metadataManagement/institution/list"));
const AddInstitution = lazy(() => import("../pages/metadataManagement/institution/Edit"));
 
//Course Level........................................................................................
const ListCourseLevel = lazy(() => import("../pages/metadataManagement/courseLevel/list"));
const AddCourseLevel = lazy(() => import("../pages/metadataManagement/courseLevel/Edit"));

//CMS............................................................ ............................
const CmsPage = lazy(() => import("../pages/content/cms/CmsPage"));
const ViewCms = lazy(() => import("../pages/content/cms/ViewCms"));
const AddEditCms = lazy(() => import("../pages/content/cms/AddEditCms"));

//MyAccount........................................................................................
const MyAccount = lazy(() => import("../pages/myAccount/MyAccount"));

// questionnaire
const TaskAndQuestionnaireList = lazy(() => import("../pages/questionnaireManagement/List"));
const EditTaskAndQuestionnaire = lazy(() => import("../pages/questionnaireManagement/Edit"));

// task 
const TaskList = lazy(() => import("../pages/taskManagement/List"));
const EditAddTask = lazy(() => import("../pages/taskManagement/Edit"));
const ViewTask = lazy(() => import("../pages/taskManagement/ViewTask"));

// Notification 
const NotificationList = lazy(() => import("../pages/Notificationmanagement/ListNotification"));

// report-statistics-management
const Reportstatisticsmanagement = lazy(() => import("../pages/reportstatisticsmanagement/StudentReport/List"));
const ViewSinglepage = lazy(() => import("../pages/reportstatisticsmanagement/StudentReport/ViewSinglepage"));

// Teacher Student Report Download
const TeacherStudentReportDownload = lazy(() => import("../pages/reportstatisticsmanagement/ReportDownload/List"));


// courses
const CourseList = lazy(() => import("../pages/courses/list"));
const CourseEdit = lazy(() => import("../pages/courses/Edit"));

const PrivateLayout = lazy(() => import("../layouts/private"));
const Page404 = lazy(() => import("../pages/error/Page404"));
const DashboardPage = lazy(() => import("../pages/dashboard/DashboardPage"));
const LoadingFallback = <div>Loading...</div>;

const makeLazyComponent = (importFunc) => (
  <Suspense fallback={LoadingFallback}>{importFunc()}</Suspense>
);

const MainRouting = () => {
  const routes = [
    {
      element: <PrivateLayout />,
      errorElement: <Page404 />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        {
          path: "dashboard",
          element: makeLazyComponent(() => <DashboardPage />),
        },

        {
          path: "resetpassword",
          element: makeLazyComponent(() => <ChangePassword />),
        },
        {
          path: "profile",
          element: makeLazyComponent(() => <MyAccount />),
        },    
        {
          path: "global-settings",
          element: makeLazyComponent(() => <GlobalSetting />),
        },
        {
          path: "language",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <ListLanguage />),
            },
            { path: "add", element: makeLazyComponent(() => <AddLanguage />) },
            { path: "edit/:id", element: makeLazyComponent(() => <AddLanguage />) },
          ],
        },
        {
          path: "institution",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <ListInstitution />),
            },
            { path: "add", element: makeLazyComponent(() => <AddInstitution />) },
            { path: "edit/:id", element: makeLazyComponent(() => <AddInstitution />) },
          ],
        },
        {
          path: "course-level",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <ListCourseLevel />),
            },
            { path: "add", element: makeLazyComponent(() => <AddCourseLevel />) },
            { path: "edit/:id", element: makeLazyComponent(() => <AddCourseLevel />) },
          ],
        },
        {
          path: "cms",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <CmsPage />),
            },
            {
              path: "add",
              element: makeLazyComponent(() => <AddEditCms />),
            },

            {
              path: "edit/:id",
              element: makeLazyComponent(() => <AddEditCms />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewCms />),
            },
          ],
        },      
        {
          path: "student-performance-report",
          children: [
            { index: true, element: makeLazyComponent(() => <Reportstatisticsmanagement />) }, 
            { path: "view/:id", element: makeLazyComponent(() => <ViewSinglepage />), },                       
          ]
        }, 
        {
          path: "student-&-teacher-registration-report",
          children: [
            { index: true, element: makeLazyComponent(() => <TeacherStudentReportDownload />) }, 
          ]
        }, 
        {
          path: "questionnaire",
          children: [
            { index: true, element: makeLazyComponent(() => <TaskAndQuestionnaireList />) },
            { path: "add", element: makeLazyComponent(() => <EditTaskAndQuestionnaire />) },
            { path: "edit/:id", element: makeLazyComponent(() => <EditTaskAndQuestionnaire />) }
          ]
        },
        {
          path: "task",
          children: [
            { index: true, element: makeLazyComponent(() => <TaskList />) },
            { path: "add", element: makeLazyComponent(() => <EditAddTask />) },
            { path: "view/:id", element: makeLazyComponent(() => <ViewDetailsTask />) },
            { path: "edit/:id", element: makeLazyComponent(() => <EditAddTask />) },
            { path: "view-task/:id", element: makeLazyComponent(() => <ViewTask />), },  
            { path: "view-report-per-task-for-student/:studentId/:courseId/:taskId", element: makeLazyComponent(() => <ViewReportPerTaskForStudent />),}                      
          ]
        },
        {
          path: "email-template",
          children: [
            { index: true, element: makeLazyComponent(() => <EmailTemplateLits />) },
            { path: "add", element: makeLazyComponent(() => <EditAddEmail />) },
            { path: "edit/:id", element: makeLazyComponent(() => <EditAddEmail />) },
            { path: "view/:id", element: makeLazyComponent(() => <ViewEmailTemplate />), },
          ]
        },
        {
          path: "courses-management",
          children: [
            { index: true, element: makeLazyComponent(() => <CoursesManagement />) },
            { path: "add", element: makeLazyComponent(() => <EditAddCoursesManagement />) },
            { path: "edit/:id", element: makeLazyComponent(() => <EditAddCoursesManagement />) },
            { path: "view/:id", element: makeLazyComponent(() => <ViewCourceManagement />), },
          ]
        },
        {
          path: "teacher-management",
          children: [
            { index: true, element: makeLazyComponent(() => <TeacherManagement />) },
            { path: "add", element: makeLazyComponent(() => <AddTeacherManagement />) },
            { path: "edit/:id", element: makeLazyComponent(() => <EditTeacherManagement />) },
            { path: "view/:id", element: makeLazyComponent(() => <ViewTeacherManagement />), },
            { path: "all-student-list/:id", element: makeLazyComponent(() => <AllStudentList />), },            
          ]
        },        
        {
          path: "student-management",
          children: [
            { index: true, element: makeLazyComponent(() => <StudentManagement />) },
            { path: "add", element: makeLazyComponent(() => <AddStudentManagement />) },
            { path: "edit/:id", element: makeLazyComponent(() => <EditStudentManagement />) },
            { path: "view/:id", element: makeLazyComponent(() => <ViewStudentManagement />), },
            { path: "viewgrade/:id", element: makeLazyComponent(() => <ViewStudentGrades />), },
            // { path: "student-liview-report-per-task/:id", element: makeLazyComponent(() => <StudentliviewReportPerTask />), },
            { path: "student-liview-report-per-task/:studentId/:courseId/:taskId", element: makeLazyComponent(() => <StudentliviewReportPerTask />),}
          ]
        },   
        {
          path: "notification-management",
          children: [
            { index: true, element: makeLazyComponent(() => <NotificationList />) },            
          ]
        }, 
        
           
        {
          path: "courses",
          children: [
            { index: true, element: makeLazyComponent(() => <CourseList />) },
            { path: "add", element: makeLazyComponent(() => <CourseEdit />) },
            { path: "edit/:id", element: makeLazyComponent(() => <CourseEdit />) }
          ]
        },
        {
          path: "irb-consent-form",
          children: [
            { index: true, element: makeLazyComponent(() => <EditAddIrbc />) },
          ]
        },
      ],
    },
    { path: "*", element: <Navigate to="/dashboard" /> },
  ];

  return useRoutes(routes);
};



export default MainRouting;