import { Box, Button, Card, CardContent, CardHeader, IconButton, Tooltip, TextField, MenuItem, Switch } from "@mui/material";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../components/CustomLoader";
import errorHandler from "../../utils/errorHandler";
import { getSrno } from "../../utils/helpers";
import SearchInput from "../../components/SearchInput";
import { toast } from "react-toastify";
import { EditOutlined, Grade, Numbers, Score } from "@mui/icons-material";
import TeacherManagementService from "../../services/TeacherManagementService";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { fDate } from "../../utils/formatTime";
  
export default function StudentManagement() {
const navigate = useNavigate();

const [paginData, setPaginData] = useState({
    totalCount: 0,
    data: [],
});
const [loading, setLoading] = useState(false);
const [refresh, setRefresh] = useState(false);
const [statusFilter, setStatusFilter] = useState("all");
const [payload, setPayload] = useState({
    per_page: 10,
    current_page: 1,
    search: "",
    sortObject: { createdAt: -1 },
    type: "student",
    start_date: null,
    end_date: null,
    status: "",
});

const fetchInstitutionList = async () => {
    try {
    setLoading(true);
    let res = await TeacherManagementService.getAll({
        ...payload,
        status: payload.status, 
        start_date: payload.start_date,
        end_date: payload.end_date,
    });
    setPaginData({
        data: res?.data?.data,
        totalCount: res?.data?.totalCount,
    });
    } catch (error) {
    errorHandler(error);
    } finally {
    setLoading(false);
    }
};

useEffect(() => {
    fetchInstitutionList();
}, [payload, refresh]);

const deleteData = async (id) => {
    Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#44070B",
    confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
    if (result.isConfirmed) {
        try {
        const res = await TeacherManagementService.delete({ id });
        if (res?.status === 200) {
            toast.success(res?.data?.message, { toastId: "success" });
            setPaginData((prev) => ({
            ...prev,
            data: prev.data.filter((item) => item._id !== id),
            totalCount: prev.totalCount - 1,
            }));
        } else {
            toast.error(res?.data?.message, { toastId: "error" });
        }
        } catch (error) {
        console.error("Error deleting...", error);
        errorHandler(error);
        }
    }
    });
};

const columns = [
    {
    name: "Sr. No.",
    cell: (row, index) =>
        getSrno(payload.current_page, payload.per_page, index),
    grow: 0.6,
    },
    {
    name: "Name",
    sortField: "name",
    sortable: true,
    selector: (row) => row?.name,
    },
    {
    name: "Email ID",
    sortField: "emailid",
    sortable: true,
    selector: (row) => row?.email,
    },
    {
    name: "Date",
    sortField: "createdAt",
    sortable: true,
    selector: (row) => fDate(row?.createdAt),
    },
    {
    name: "Status",
    sortField: "isBlocked",
    sortable: true,
    cell: (row) => (
        <>

        <Tooltip title={row.isBlocked ? "Activate" : "Deactivate"} placement="right">
        <Switch
        checked={!row.isBlocked}
        onChange={async (e) => {
            const newStatus = !row.isBlocked;
            await toggleUserStatus(row._id, newStatus);
        }}
        />
        </Tooltip>
        </>
    ),
    },
    {
    name: "Action",
    right: true,
    cell: (row) => (
        <>
            <Tooltip title="Edit" placement="left">
                <IconButton
                color="primary"
                onClick={() => {
                    navigate(`/student-management/edit/${row._id}`);
                }}
                >
                <EditOutlined />
                </IconButton>
            </Tooltip>

            <Tooltip title="View" placement="left" leaveDelay={50}>
                <IconButton
                color="primary"
                onClick={() => {
                    navigate(`/student-management/view/${row._id}`);
                }}
                >
                <VisibilityIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="View Grade" placement="right" leaveDelay={50}>
                <IconButton
                color="primary"
                onClick={() => {
                    navigate(`/student-management/viewgrade/${row._id}`);
                }}
                >
                <Grade />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="right">
                <IconButton color="primary" onClick={() => deleteData(row?._id)}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </>
        ),
    },
];

const toggleUserStatus = async (id, newStatus) => {
    try {
    const res = await TeacherManagementService.updateStatus({ id, isBlocked: newStatus });

    if (res?.status === 200) {
        toast.success(res?.data?.message, { toastId: "success" });
        setPaginData((prev) => ({
        ...prev,
        data: prev.data.map((item) =>
            item._id === id ? { ...item, isBlocked: newStatus } : item
        ),
        }));
        setRefresh(!refresh)
    } else {
        toast.error(res?.data?.message, { toastId: "error" });
    }
    } catch (error) {
    console.error("Error updating status...", error);
    errorHandler(error);
    }
};


const handleSort = (column, sortDirection) => {
    const srt = {
    [column.sortField]: sortDirection === "asc" ? -1 : 1,
    };
    setPayload((prev) => ({
    ...prev,
    sortObject: srt,
    }));
};

return (
    <Card>
    <CardHeader
        titleTypographyProps={{ variant: "h4" }}
        title="Student Management"
    />
    <Box
        sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        }}
    >
        <SearchInput data={payload} setSearch={setPayload} />
        <Button
            size="large"
            type="submit"
            variant="contained"
            sx={{ ml: 3, mr: 3 }}
            onClick={() => navigate("/student-management/add")}
        >
         Add
        </Button>
    </Box>
    <CardContent>

        <TextField
            label="Start Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={payload.start_date ? new Date(payload.start_date).toISOString().split('T')[0] : ""}
            onChange={(e) =>
                setPayload((prev) => ({ ...prev, start_date: new Date(e.target.value) }))
            }
            sx={{ marginRight: 2 }}
        />

        <TextField
            label="End Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={payload.end_date ? new Date(payload.end_date).toISOString().split('T')[0] : ""}
            onChange={(e) => {
                const selectedEndDate = new Date(e.target.value);
                selectedEndDate.setHours(23, 59, 59);
                setPayload((prev) => ({ ...prev, end_date: selectedEndDate }));
            }}
        />

        <TextField
            select
            label="Status"
            value={statusFilter}
            onChange={(e) => {
                setStatusFilter(e.target.value);
                setPayload((prev) => ({
                ...prev,
                status: e.target.value === "all" ? "" : e.target.value, 
                }));
            }}
            sx={{ marginLeft: 2 }}
        >
        <MenuItem value={"all"}>All</MenuItem>
        <MenuItem value={"false"}> Active</MenuItem>
        <MenuItem value={"true"}>Inactive</MenuItem>

        </TextField>

        <Button
            size="large"
            type="submit"
            variant="outlined"
            sx={{ ml: 3, mr: 3 }}
            onClick={() => {
                setPayload({
                per_page: 10,
                current_page: 1,
                search: "",
                sortObject: { createdAt: -1 },
                type: "student",
                start_date: null,
                end_date: null,
                status: "",
                });
                setStatusFilter("all");
            }}
        >
        Clear
        </Button>       

        <DataTable
            customStyles={{
                headCells: {
                style: {
                    fontSize: "15px",
                    fontWeight: "bold",
                },
                },
            }}
            columns={columns}
            data={paginData?.data}
            pagination
            paginationServer
            paginationTotalRows={paginData?.totalCount}
            onChangePage={(page) =>
                setPayload((prev) => ({ ...prev, current_page: page }))
            }
            paginationPerPage={payload.per_page}
            paginationRowsPerPageOptions={[5, 10, 20, 50]}
            onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                setPayload((prev) => ({
                ...prev,
                per_page: currentRowsPerPage,
                current_page: currentPage,
                }));
            }}
            progressPending={loading}
            onSort={handleSort}
            progressComponent={<CustomLoader />}
        />
    </CardContent>
    </Card>
);
}
